/* Add any additional styling for the Navbar here */
.navbar-light .navbar-nav .nav-link {
    color: #555;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-brand {
    color: #555;
}

.navbar-dark .navbar-brand {
    color: #fff;
}